import React from "react"

import Layout from "../templates/layout"
import { SEO, Text, Heading } from "../components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Heading>404: Not Found</Heading>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </Layout>
)

export default NotFoundPage
